@import '../../../assets/styles/variables';

#aside {

    ul>*,
    .ant-menu-item .anticon+span,
    .ant-menu-submenu-title .anticon+span {
        font-family: $Montserrat400;
        font-size: $desctop14px;
    }

    .ant-layout-sider {
        flex: 0 0 17vw !important;
        max-width: 17vw !important;
        min-width: 17vw !important;
        width: 17vw !important;
        padding: $desctop20px 0 0 $desctop30px;
    }

    .ant-layout-sider-collapsed {
        flex: 0 0 7vw !important;
        max-width: 7vw !important;
        min-width: 7vw !important;
        width: 7vw !important;
        padding-left: 2.6vw;

        .ant-layout-sider-trigger {
            background: transparent;
            width: 100% !important;
        }
    }

    .ant-menu-inline-collapsed {
        width: initial;
    }

    .ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-layout-sider {
        background: transparent;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background: transparent;
    }

    .ant-menu-dark .ant-menu-item-selected .anticon,
    .ant-menu-dark .ant-menu-item-selected .anticon+span,
    .ant-menu-dark .ant-menu-item-selected,
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active {
        color: $redColor;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: transparent;
        box-shadow: none;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        padding: 0 !important;
        margin: 0;
    }

    .ant-menu-submenu-title {
        margin: 0;
    }

    .ant-menu-submenu {
        ul {
            margin-left: $desctop24px;
        }
    }

    .ant-menu-item,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: $desctop60px;
        line-height: $desctop60px;
    }

    .ant-menu-submenu .ant-menu-inline > .ant-menu-item {
        height: $desctop40px;
        line-height: $desctop40px;
    }

    .ant-layout-sider-trigger {
        position: static;
        width: 93% !important;
        height: $desctop50px;
        line-height: $desctop50px;
        background: $midnightExpressColor;
        margin-top: $desctop20px;
    }

    .ant-menu-inline-collapsed > .ant-menu-item .anticon,
    .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
        font-size: $desctop20px;
        line-height: $desctop60px;
        height: $desctop60px;
    }

    .toggleBtn,
    .toggleBtnActive {
        border-top: $desctop2px solid $martiniqueapproxLight;
        padding: $desctop20px 0 $desctop20px $desctop30px;
        font-family: $Montserrat400;
        font-size: $desctop14px;
        color: rgba(255, 255, 255, 0.65);
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            color: $whiteColor;
        }

        i {
            min-width: $desctop14px;
            margin-right: $desctop10px;
            font-size: $desctop14px;
        }
    }

    .toggleBtnActive {
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
        min-width: $desctop14px;
        margin-right: $desctop10px;
        font-size: $desctop14px;
    }
}
