@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.burgerMenu {
    display: none;

    @media only screen and (max-width: 499px) {
        display: block;
        position: relative;
        z-index: 1000;
    }
}

.linksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow-y: hidden;
    width: 85%;
    position: relative;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    ul {
        width: 100%;
    }
}

.links {
    &_item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: $mobile1px solid $solitudeColor;
        position: relative;

        a,
        div {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 4.7vw 0;
        }

        svg {
            width: $mobile10px;
            height: $mobile10px;
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translateY(-50%);

            path {
                fill: $tolopeaColor;
            }
        }
    }

    &_item>* {
        font-family: $Montserrat500;
        color: $tolopeaColor;
        font-size: $mobile10px;
        letter-spacing: $mobile1px;
        text-transform: uppercase;

        &:hover {
            color: $redColor;
        }
    }

    &_subLinks {
        @media only screen and (max-width: 499px) {
            position: absolute;
            top: 5vw;
            overflow-Y: auto;
            max-height: 74%;
            width: 99vw;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            a {
                font-family: $Montserrat500;
                color: $tolopeaColor;
                font-size: $mobile10px;
                letter-spacing: $mobile1px;
                text-transform: uppercase;
                padding: 4.7vw 0;
                width: 100%;
                display: flex;
                justify-content: center;

                &:hover {
                    color: $redColor;
                }
            }
        }
    }
}

.drawer__opened,
.drawer__closed {
    display: none;

    @media only screen and (max-width: 499px) {
        display: block;
        background: $whiteColor;
        width: 100vw;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: transform 0.5s;
        z-index: 100;
        transition: visibility 0.25s, opacity 0.1s linear;
    }
}

.drawer__opened {
    visibility: visible;
    opacity: 1;
}

.drawer__closed {
    visibility: hidden;
    opacity: 0;
}

.back {
    width: fit-content;
    padding: $mobile15px;
    margin: 40vw auto $mobile15px;
    font-family: $Montserrat500;
    font-size: $mobile13px;
    line-height: $mobile10px;
    letter-spacing: $mobile1px;
    text-transform: uppercase;
    color: rgba(38, 38, 66, 0.5);
    display: flex;
    align-items: center;

    &__arrow {
        width: $mobile7px;
        height: $mobile10px;
        margin-right: $mobile5px;
    }
}
