@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.about {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px;
    position: relative;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop30px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
                line-height: $mobile42px;
                font-size: $mobile25px;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        &_text {
            font-family: $Montserrat400;
            line-height: $desctop32px;
            font-size: $desctop16px;
            color: $whiteColor;
            margin-bottom: $desctop30px;

            @media only screen and (max-width: 499px) {
                line-height: $mobile28px;
                font-size: $mobile14px;
                margin-bottom: $mobile30px;
            }

            b {
                font-family: $Montserrat900;
            }
        }

        &_title {
            font-family: $Montserrat900;
            font-size: $desctop25px;
            line-height: $desctop35px;
            color: $whiteColor;
            margin-bottom: $desctop25px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile20px;
                line-height: $mobile34px;
                margin-bottom: $mobile20px;
            }
        }
    }

    &__list {
        display: flex;
        align-items: flex-start;

        img {
            width: $desctop15px;
            height: $desctop15px;
            margin: $desctop10px $desctop30px 0 0;

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }
    }

    &__map {
        width: 80%;
        display: flex;
        margin: $desctop80px auto;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin: $mobile20px auto $mobile42px;
        }
    }

    &__contentBg {
        background: $martiniqueapproxLight;
        padding: $desctop30px;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            padding: $mobile20px;
            margin-bottom: $mobile20px;
        }
    }

    &__background {
        margin: $desctop40px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: auto;
        }
    }
}
