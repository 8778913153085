@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.joinUs {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px;
    position: relative;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px $mobile30px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop30px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            max-width: 50vw;
            color: $whiteColor;
            text-transform: uppercase;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 90%;
            }

            span {
                color: $redColor;
            }
        }
    }
}

.partners {
    &__content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        background: $martiniqueapprox;

        @media only screen and (max-width: 499px) {
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        &_link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22vw;
            height: 10vw;
            margin-bottom: $desctop40px;

            @media only screen and (max-width: 499px) {
                width: 50%;
                height: 25vw;

                &:not(:last-child) {
                    margin-bottom: $mobile42px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: 100%;
                width: $desctop2px;
                height: 100%;
                top: 0;
                background: linear-gradient(rgba(255, 255, 255, 0.0001),rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

                @media only screen and (max-width: 499px) {
                    width: $mobile2px;
                }
            }

            img {
                width: $desctop200px;
                object-fit: scale-down;

                @media only screen and (max-width: 499px) {
                    width: 30vw;
                }
            }
        }
    }
}
