@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.container {
    width: 100%;
    padding: 0 $desctop80px 0 3.7vw;
    border-top: $desctop2px solid $martiniqueapproxLight;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $desctop15px;

        &_title {
            font-family: $Montserrat900;
            font-size: $desctop40px;
            line-height: $desctop60px;
            color: $whiteColor;
        }

        &_github {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $blueGradient;
            border-radius: $desctop10px;
            width: 11.81vw;
            height: 5.1vw;

            &:hover {
                border: $desctop2px solid $martiniqueapproxLight;
            }

            img {
                width: $desctop45px;
                height: $desctop45px;
                margin-right: $desctop17px;
            }

            span {
                font-family: $Montserrat400;
                font-size: $desctop14px;
                line-height: $desctop60px;
                color: $whiteColor;
            }
        }
    }
}

.lastEdit {
    display: flex;
    align-items: center;
    margin-top: $desctop10px;

    &__img {
        width: $desctop40px;
        height: $desctop40px;
        border-radius: 50%;
        object-fit: contain;
        margin-right: $desctop19px;
    }

    &__wrapper {
        display: flex;
        align-items: center;

        span,a {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: $desctop32px;
            color: $whiteColor;
            opacity: 0.89;
        }

        a {
            margin: 0 $desctop10px;

            &:hover {
                color: $redColor;
            }
        }
    }
}
