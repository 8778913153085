@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.firstScreen {
    background: $martiniqueapprox;
    height: 100vh;
    min-height: 42vw;
    padding: $desctop40px $desctop135px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: auto;
        min-height: 50vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile20px $mobile30px;
        height: auto;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop60px;
        line-height: $desctop60px;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile34px;
            line-height: $mobile44px;
        }

        span {
            color: $redColor;
        }
    }

    &__subTitle {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop32px;
        color: $whiteColor;
        opacity: 0.89;
        margin: $desctop40px 0;
        max-width: 28vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile21px;
            max-width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__button {
        @include mainBtnStyle;
        @include filledBtn;
    }

    &__backgroundImg {
        position: absolute;
        top: 47%;
        transform: translateY(-50%);
        right: 8vw;
        height: 40vw;

        @media only screen and (max-width: 499px) {
            position: static;
            top: initial;
            transform: translate(-5.5vw, 6vw);
            height: auto;
            width: 100vw;
            margin-bottom: $mobile42px;
        }
    }
}

.socialIcons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-46%);

    @media only screen and (max-width: 499px) {
        top: 15%;
        transform: initial;
    }

    &__link {
        padding: $desctop36px;

        @media only screen and (max-width: 499px) {
            padding: $mobile15px $mobile30px;
        }

        &:hover {
            svg path {
                fill: $redColor;
            }
        }
    }

    svg {
        width: $desctop13px;
        height: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: $mobile13px;
            height: $mobile13px;
        }
    }
}
