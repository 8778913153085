@import '../.././assets/styles/variables';

.privacyPolicy {
    flex: 1;
    padding: 6.778vw 9.376vw 0;
    background: $martiniqueapprox;
    position: relative;

    @media only screen and (max-width: 499px) {
        padding: 21vw 7vw 7vw;
    }

    &__date {
        font-family: $Montserrat400;
        font-size: $desctop14px;
        color: $redColor;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            margin-bottom: $mobile20px;
        }
    }

    &__text {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop24px;
        color: #fff;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            margin-bottom: $mobile20px;
            line-height: $mobile24px;
        }

        a {
            color: rgba(255, 255, 255, 0.5);
            margin: 0 $desctop5px;

            @media only screen and (max-width: 499px) {
                margin: 0 $mobile5px;
            }

            &:hover {
                color: $redColor;
            }
        }
    }

    &__wrapper {
        margin-bottom: $desctop45px;

        @media only screen and (max-width: 499px) {
            margin-bottom: 10vw;
        }
    }

    &__mainTitle {
        font-family: $Montserrat900;
        font-size: 2.778vw;
        color: #fff;
        text-align: center;
        margin-bottom: 4vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile18px;
            margin-bottom: 8vw;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        font-family: $Montserrat600;
        font-size: $desctop18px;
        color: $redColor;
        margin-bottom: 1vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            margin-bottom: 3vw;
        }
    }

    &__content {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop24px;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
        }
    }

    &__list {
        list-style: disc;
        font-family: $Montserrat600;
        font-size: $desctop16px;
        line-height: $desctop20px;
        color: #404040;
        margin-bottom: 1vw;
        margin-left: 1.8vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
            margin-left: 6.8vw;
        }
    }
}
