@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.firstScreen {
    background: $martiniqueapprox;
    height: 100vh;
    min-height: 42vw;
    padding: $desctop40px $desctop135px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: auto;
        min-height: 50vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $desctop40px $mobile44px $desctop40px $mobile20px;
        height: auto;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop60px;
        line-height: $desctop60px;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile34px;
            line-height: $mobile44px;
        }

        span {
            color: $redColor;
        }
    }

    &__subTitle {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop32px;
        color: $whiteColor;
        opacity: 0.89;
        margin: $desctop40px 0;
        max-width: 28vw;
        margin-bottom: $desctop80px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile21px;
            max-width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__backgroundImg {
        position: absolute;
        top: 47%;
        transform: translateY(-50%);
        right: 8vw;
        height: 40vw;
        width: 45%;

        @media only screen and (max-width: 499px) {
            position: static;
            top: initial;
            transform: translate(-5.5vw, 6vw);
            height: auto;
            width: 100vw;
            margin-bottom: $mobile42px;
        }
    }

    &__form {

        &_buttonFilled,
        &_button {
            @include mainBtnStyle;
            margin-right: $desctop35px;

            @media only screen and (max-width: 499px) {
                &:first-child {
                    margin: 0 0 $mobile30px 0;
                }
            }
        }

        &_buttonFilled {
            @include filledBtn;
        }

        &_button {
            @include transparentBtn;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            position: static;
            margin-bottom: $mobile20px;
        }

        &_title {
            min-width: 33%;
            width: 33%;
            font-family: $Montserrat400;
            font-size: $desctop14px;
            line-height: 2.4;
            color: $whiteColor;
            padding-right: $desctop30px;
            margin-right: $desctop50px;
            position: relative;

            @media only screen and (max-width: 499px) {
                min-width: 100%;
                width: 100%;
                font-size: $mobile12px;
                padding-right: 0;
                margin-right: 0;
                margin-bottom: $mobile20px;
            }

            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: $desctop63px;
                width: $desctop1px;
                background: linear-gradient(1turn, rgba(32, 32, 62, .0001), #fff);

                @media only screen and (max-width: 499px) {
                    display: none;
                }
            }
        }
    }
}

.socialIcons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-46%);

    @media only screen and (max-width: 499px) {
        top: 15%;
        transform: initial;
    }

    &__link {
        padding: $desctop36px;

        @media only screen and (max-width: 499px) {
            padding: $mobile15px $mobile30px;
        }

        &:hover {
            svg path {
                fill: $redColor;
            }
        }
    }

    svg {
        width: $desctop13px;
        height: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: $mobile13px;
            height: $mobile13px;
        }
    }
}


.container {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px $desctop60px;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile30px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop80px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile42px;
        }

        &_text {
            line-height: $desctop32px;
            font-size: $desctop16px;
            font-family: $Montserrat400;
            color: $whiteColor;
            max-width: 85%;

            @media only screen and (max-width: 499px) {
                line-height: $mobile26px;
                font-size: $mobile14px;
                max-width: 100%;
            }
        }
    }
}

.achievements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
    }

    &__item {
        display: flex;
        width: 45%;
        margin-bottom: $desctop50px;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile50px;
        }

        &_img {
            width: $desctop80px;
            height: $desctop60px;
            margin-right: $desctop40px;

            @media only screen and (max-width: 499px) {
                width: 22vw;
                height: 22vw;
                margin-right: $mobile30px;
            }
        }

        &_title {
            font-size: $desctop24px;
            font-family: $Montserrat500;
            line-height: 1.2;
            margin-bottom: $desctop10px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile18px;
                margin-bottom: $mobile10px;
            }
        }

        &_text {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: 2;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                line-height: 1.5;
            }
        }
    }
}
