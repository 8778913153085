@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.media {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px $desctop60px;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &_text {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: $desctop32px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile32px;
            }
        }
    }

    &__telegram {
        display: flex;
        align-items: flex-end;
        margin-top: $desctop26px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: $mobile10px;
        }

        &_item {
            display: flex;
            margin: 0 0 $desctop30px $desctop70px;

            @media only screen and (max-width: 499px) {
                margin: 0 0 $mobile30px 0;
            }
        }

        &_redPath,
        &_greenPath {
            width: $desctop18px;
            margin-right: $desctop20px;

            @media only screen and (max-width: 499px) {
                width: $mobile18px;
                margin: 0 $mobile10px 0 0;
            }
        }

        &_redPath {
            height: 5.5vw;

            @media only screen and (max-width: 499px) {
                height: 24vw;
            }
        }

        &_greenPath {
            height: $desctop55px;

            @media only screen and (max-width: 499px) {
                height: $mobile55px;
            }
        }

        &_icon {
            width: $desctop135px;
            height: $desctop259px;

            @media only screen and (max-width: 499px) {
                width: 36vw;
                height: 70vw;
                margin-bottom: $mobile42px;
            }
        }

        &_text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                font-family: $Montserrat500;
                font-size: $desctop13px;
                letter-spacing: $desctop1px;
                text-transform: uppercase;
                color: $whiteColor;
                opacity: 0.89;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile10px;
                    line-height: $mobile16px;
                    letter-spacing: $mobile1px;
                    white-space: nowrap;
                }
            }

            a {
                color: $redColor;
                font-size: $desctop13px;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile10px;
                }

                &:hover {
                    color: $redColor;
                    text-decoration: underline;
                }
            }
        }
    }

    &__connectToUs {
        margin: $desctop50px 0 $desctop75px;

        @media only screen and (max-width: 499px) {
            margin: $mobile44px 0 $mobile30px;
        }

        &_text {
            font-family: $Montserrat900;
            font-size: $desctop30px;
            line-height: $desctop50px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile25px;
                line-height: $mobile34px;
            }

            span {
                color: $redColor;
            }
        }
    }
}

.socialIcons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
    }

    &__link {
        padding: $desctop36px;

        @media only screen and (max-width: 499px) {
            padding: $mobile30px;
        }

        &:hover {
            svg path {
                fill: $redColor;
            }
        }
    }

    svg {
        width: $desctop35px;
        height: $desctop35px;

        @media only screen and (max-width: 499px) {
            width: $mobile28px;
            height: $mobile28px;
        }
    }
}
