@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.ambassadors,
.team {
    padding: $desctop60px $desctop135px $desctop30px;
    position: relative;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;
        position: relative;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $tolopeaColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            margin-bottom: 0;
        }

        &_item {
            width: 20.9vw;
            box-sizing: content-box;
            margin: $desctop15px $desctop50px $desctop40px 0;
            outline: none;
            z-index: 10;

            @media only screen and (max-width: 499px) {
                flex: initial;
                width: 100%;

                &:not(:last-child) {
                    padding: 0 0 $mobile42px 0;
                }

                &:last-child {
                    padding: 0;
                }
            }

            &:hover {
                .ambassadors__content_photo {
                    filter: initial;
                }

                .team__content_photo {
                    filter: initial;
                }
            }
        }

        &_itemBlur {
            filter: blur($desctop4px);
            z-index: 1;
        }

        &_photo {
            filter: grayscale(1);

            @media only screen and (max-width: 499px) {
                filter: initial;
            }
        }

        &_photo,
        &_photoActive {
            width: 20.9vw;
            height: 20.9vw;
            border-radius: $desctop20px;
            object-fit: cover;

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: 100%;
                border-radius: $mobile20px;
            }
        }

        &_name {
            font-family: $Montserrat900;
            line-height: $desctop34px;
            font-size: $desctop24px;
            color: $tolopeaColor;
            padding-top: $desctop10px;

            @media only screen and (max-width: 499px) {
                line-height: $mobile34px;
                font-size: $mobile24px;
                padding-top: $mobile10px;
            }
        }

        &_position {
            width: 20.9vw;
            height: 2.5vw;
            font-family: $Montserrat900;
            font-size: $desctop12px;
            line-height: 1.2vw;
            text-align: center;
            letter-spacing: $desctop1px;
            text-transform: uppercase;
            color: $redColor;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin: 3vw 0 2vw;
                font-size: $mobile12px;
                line-height: $mobile12px;
                letter-spacing: $mobile1px;
            }
        }

        &_container {
            position: relative;
            min-height: 13.5vw;

            @media only screen and (max-width: 499px) {
                min-height: initial;
            }
        }

        &_descriptionWr {
            position: absolute;
            padding: 0 $desctop10px $desctop10px $desctop10px;
            border-bottom-right-radius: $desctop32px;
            border-bottom-left-radius: $desctop32px;
            left: -0.7vw;
            width: calc(100% + 1.4vw);

            @media only screen and (max-width: 499px) {
                position: static;
                padding: 0;
            }
        }

        &_description,
        &_descriptionActive {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: $desctop32px;
            color: $tolopeaColor;
            position: relative;

            @media only screen and (max-width: 499px) {
                font-size: $mobile16px;
                line-height: $mobile32px;
            }

            a {
                color: $redColor;

                &:hover {
                    color: $redColor;
                    text-decoration: underline;
                }
            }
        }

        &_description {
            height: $desctop160px;
            height: 11vw;
            overflow: hidden;

            @media only screen and (max-width: 499px) {
                height: initial;
                overflow: initial;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 7vw;
                width: 100%;
            }
        }
    }

    &__socials {
        display: flex;
        margin: $desctop13px 0 $desctop14px 0;
        height: $desctop19px;

        @media only screen and (max-width: 499px) {
            margin: $mobile13px 0 $mobile14px 0;
            height: initial;
        }

        &_linkedIn,
        &_facebook {
            display: flex;
            align-items: inherit;

            &:hover {
                opacity: 0.8;
            }

            img {
                width: 100%;
            }
        }

        &_linkedIn {
            width: $desctop20px;
            height: $desctop19px;
            margin-right: $desctop20px;

            @media only screen and (max-width: 499px) {
                width: $mobile20px;
                height: $mobile19px;
                margin-right: $mobile20px;
            }
        }

        &_facebook {
            width: $desctop12px;
            height: $desctop19px;

            @media only screen and (max-width: 499px) {
                width: $mobile12px;
                height: $mobile19px;
            }
        }
    }

    &__container {
        z-index: 10;
    }

    &__activeContainer {
        z-index: 100;
    }
}

.seeMore {
    display: flex;
    align-items: center;
    margin: $desctop10px 0;
    cursor: pointer;

    @media only screen and (max-width: 499px) {
        margin: $mobile10px 0;
    }

    &:hover {
        opacity: 0.7;
    }

    p {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop10px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $redColor;
        margin-right: $desctop10px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            letter-spacing: $mobile1px;
            margin-right: $mobile10px;
        }
    }

    img {
        width: $desctop28px;
        height: $desctop24px;

        @media only screen and (max-width: 499px) {
            width: $mobile28px;
            height: $mobile24px;
        }
    }
}

.ambassadors {
    background: $whiteColor;
    z-index: 1;

    &__content {

        &_item {
            background-color: $whiteColor;
        }

        &_position {
            background: $whiteColor;
        }

        &_descriptionWr {
            background: $whiteColor;
        }

        &_description {
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 7vw;
                width: 100%;
                background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 99%);
            }
        }
    }
}

.team {
    background: $zirconColor;
    z-index: 10;

    &__content {

        &_item {
            background-color: $zirconColor;
        }

        &_position {
            background: $zirconColor;
        }

        &_descriptionWr {
            background: $zirconColor;
            z-index: 10;
        }

        &_description {
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 7vw;
                width: 100%;
                background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #f7f8f8 99%);
            }
        }
    }
}
