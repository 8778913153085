@import "../../../assets/styles/variables";

.errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
        max-width: 20vw;
        margin-bottom: 3vw;
    }

    h2 {
        color: $tolopeaColor;
        font-family: $Montserrat500;
        font-size: 4vw;
        font-weight: 500;
        margin-bottom: 2vw;
        white-space: nowrap;
    }
}

.relative {
    flex: 1;
    position: relative;
    min-height: 30vw;
    width: -webkit-fill-available;
}
