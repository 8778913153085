@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.advantages {
    background: $zirconColor;
    padding: $desctop60px $desctop135px $desctop60px;
    position: relative;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $tolopeaColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $tolopeaColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile30px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 90%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {

            &:not(:last-child) {
                margin-bottom: $mobile30px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_item {
            padding-bottom: $desctop20px;
            border-bottom: $desctop1px solid $tolopeaColor;
            margin-bottom: $desctop20px;
            max-width: 90%;
            position: relative;
            z-index: 10;
            background: $zirconColor;

            @media only screen and (max-width: 499px) {
                padding-bottom: $mobile20px;
                border-bottom: $mobile1px solid $tolopeaColor;
                max-width: 100%;

                &:not(:last-child) {
                    margin-bottom: $mobile20px;
                }
            }

            &:hover {
                border-bottom: $desctop1px solid $redColor;

                @media only screen and (max-width: 499px) {
                    border-bottom: $mobile1px solid $redColor;
                }

                .advantages__content_number {
                    text-shadow: 0 0 $desctop1px $redColor;

                    @media only screen and (max-width: 499px) {
                        text-shadow: 0 0 $mobile1px $redColor;
                    }
                }
            }
        }

        &_top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 499px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &_title {
            font-size: $desctop24px;
            color: $tolopeaColor;
            font-family: $Montserrat900;

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
                margin-bottom: $mobile20px;
            }
        }

        &_number {
            text-shadow: 0 0 $desctop1px $tolopeaColor;
            font-family: $Montserrat900;
            font-size: $desctop60px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                text-shadow: 0 0 $mobile1px $tolopeaColor;
                font-size: $mobile60px;
                order: -1;
                margin-bottom: $mobile10px;
            }
        }

        &_text {
            font-family: $Montserrat400;
            line-height: $desctop32px;
            font-size: $desctop16px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                line-height: $mobile28px;
                font-size: $mobile14px;
            }
        }
    }
}

.seeMore {
    display: flex;
    align-items: center;
    margin: $desctop10px 0 0;
    cursor: pointer;

    @media only screen and (max-width: 499px) {
        margin: $mobile10px 0 0;
    }

    &:hover {
        opacity: 0.7;
    }

    p {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop10px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $redColor;
        margin-right: $desctop10px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            letter-spacing: $mobile1px;
            margin-right: $mobile10px;
        }
    }

    img {
        width: $desctop28px;
        height: $desctop24px;

        @media only screen and (max-width: 499px) {
            width: $mobile28px;
            height: $mobile24px;
        }
    }
}
