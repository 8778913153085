@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.aboutUs {
    background: $whiteColor;
    padding: $desctop60px $desctop135px $desctop60px;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $tolopeaColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $tolopeaColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop50px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        &_text {
            line-height: $desctop32px;
            font-size: $desctop16px;
            font-family: $Montserrat400;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                line-height: $mobile28px;
                font-size: $mobile14px;
            }

            &:first-child {
                width: 45%;

                @media only screen and (max-width: 499px) {
                    width: 100%;
                    margin-bottom: $mobile30px;
                }
            }

            &:last-child {
                width: 50%;

                @media only screen and (max-width: 499px) {
                    width: 100%;
                    margin-bottom: $mobile20px;
                }
            }
        }
    }
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 22.8vw;
        height: $desctop259px;
        background: rgba(196, 196, 196, 0.1);
        margin: 0 $desctop25px $desctop25px 0;
        border-radius: $desctop5px;
        padding: $desctop40px $desctop30px $desctop40px $desctop30px;

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: 73.7vw;
            margin: 0 0 $mobile30px 0;
            border-radius: $mobile5px;
            padding: $mobile34px;
        }

        &:hover {
            background-color: $midnightExpressColor;

            .wrapper__item_title {
                color: $whiteColor;
            }

            .wrapper__item_subTitle,
            .wrapper__item_btn {
                display: flex;
            }

            .wrapper__item_img {
                display: none;
            }
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:last-child {
            @media only screen and (max-width: 499px) {
                margin-bottom: 0;
            }
        }

        &_img {
            width: $desctop160px;
            height: $desctop124px;
            margin-bottom: $desctop20px;

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: 40vw;
                margin-bottom: $mobile20px;
            }
        }

        &_title {
            font-family: $Montserrat900;
            font-size: $desctop25px;
            line-height: $desctop40px;
            color: $tolopeaColor;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                font-size: $mobile25px;
                line-height: $mobile42px;
            }
        }

        &_subTitle {
            font-family: $Montserrat400;
            font-size: $desctop13px;
            line-height: $desctop26px;
            color: $whiteColor;
            text-align: center;
            max-width: 80%;
            margin: auto 0;
            display: none;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile26px;
                margin: auto 0;
                max-width: 100%;
            }
        }

        &_btn {
            @include mainBtnStyle;
            @include filledBtn;
            display: none;
            margin-top: auto;
            height: $desctop40px;

            @media only screen and (max-width: 499px) {
                margin-top: $mobile20px;
            }
        }
    }
}
