@mixin mainBtnStyle {
    font-family: $Montserrat600;
    font-size: $desctop12px;
    letter-spacing: $desctop1px;
    border-radius: $desctop100px;
    height: $desctop60px;
    width: fit-content;
    min-width: 14vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.25s;
    outline: none;

    @media only screen and (max-width: 499px) {
        font-size: $mobile12px;
        letter-spacing: $mobile1px;
        border-radius: $mobile70px;
        height: $mobile50px;
        min-width: 100%;
    }
}

@mixin filledBtn {
    background: $redColor;
    color: $whiteColor;
    border: $desctop1px solid transparent;

    &:hover {
        border: $desctop1px solid $redColor;
        background: transparent;
        color: $redColor;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(255, 48, 21, 0.5);
            background: rgba(255, 48, 21, 0.5);
            color: $whiteColor;
        }
    }
}

@mixin transparentBtn {
    border: $desctop1px solid $redColor;
    background: transparent;
    color: $redColor;

    &:hover {
        border: $desctop1px solid $redColor;
        background: $redColor;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(255, 48, 21, 0.5);
            background: rgba(255, 48, 21, 0.5);
            color: $whiteColor;
        }
    }
}

@mixin sublink {
    position: absolute;
    background: $whiteColor;
    border: $desctop1px solid rgba(36, 36, 68, 0.1);
    border-radius: $desctop17px;
    padding: $desctop10px $desctop20px;
    top: 2.5vw;
    left: 50%;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transform: translate(-50%, 0);

    a {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop10px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $tolopeaColor;
        display: flex;
        white-space: nowrap;
        justify-content: center;
        padding: $desctop10px 0;

        &:hover {
            color: $redColor;
        }
    }
}
