@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.container {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px $desctop60px;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile30px;
                line-height: $mobile30px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            margin-bottom: 0;
        }

        &_text {
            line-height: $desctop32px;
            font-size: $desctop16px;
            font-family: $Montserrat400;
            color: $whiteColor;
            max-width: 85%;

            @media only screen and (max-width: 499px) {
                line-height: $mobile26px;
                font-size: $mobile14px;
                max-width: 100%;
            }
        }
    }
}

.achievements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
    }

    &__item {
        display: flex;
        width: 80%;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            width: 100%;
        }

        &_img {
            width: $desctop80px;
            height: $desctop80px;
            margin-right: $desctop40px;

            @media only screen and (max-width: 499px) {
                width: 22vw;
                height: 22vw;
                margin: 0 0 $mobile30px 0;
            }
        }

        &_text {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: 2;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                line-height: $mobile32px;
            }
        }

        &_link {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: 2;
            color: $redColor;
            margin-left: $desctop4px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                line-height: 1.5;
                margin-left: $mobile5px;
            }
        }
    }
}
