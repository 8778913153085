@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.wallet {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px $desctop60px;
    display: flex;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px 0;
        flex-direction: column;
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;


        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop50px 0 0 $desctop5px;
        width: 90%;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
            width: 100%;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__bottom {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_link {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 11.9vw;
            height: 10.6vw;
            background: rgba(196, 196, 196, 0.1);
            border-radius: $desctop9px;
            cursor: pointer;

            @media only screen and (max-width: 499px) {
                width: 45%;
                height: 30vw;
                border-radius: $mobile10px;
                margin-bottom: $mobile30px;
            }

            &:hover {
                background: $blueGradient;
            }

            &:not(:last-child) {
                margin-right: $desctop20px;

                @media only screen and (max-width: 499px) {
                    margin-right: 0;
                }
            }

            img {
                width: $desctop45px;
                height: $desctop45px;

                @media only screen and (max-width: 499px) {
                    width: $mobile30px;
                    height: $mobile30px;
                }
            }

            span {
                font-family: $Montserrat500;
                font-size: $desctop12px;
                letter-spacing: $desctop1px;
                text-transform: uppercase;
                color: $whiteColor;
                margin-top: $desctop30px;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile10px;
                    letter-spacing: $mobile1px;
                    margin-top: $mobile20px;
                }
            }
        }
    }
}
