@font-face {
    font-family: 'Montserrat400';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat500';
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat600';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat600';
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat900';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue500';
    src: url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat400';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat500';
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat600';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat600';
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat900';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue500';
    src: url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
