@import '../.././assets/styles/variables';

.pageNotFound {
    display: flex;
    align-items: center;
    justify-items: center;
    flex: 1;
    padding: 120px 0 30px;

    img {
        margin: 0 auto;
        width: 11vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30%;
        }

        @media only screen and (max-width: 767px) {
            width: 50vw;
        }
    }
}
