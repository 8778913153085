@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.about {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px;
    position: relative;
    border-bottom: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop30px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile20px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 90%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        &_text {
            font-family: $Montserrat400;
            line-height: $desctop32px;
            font-size: $desctop16px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                line-height: $mobile28px;
                font-size: $mobile14px;
            }
        }
    }

    &__background {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: auto;
        }
    }
}

.seeMore {
    display: flex;
    align-items: center;
    margin: $desctop10px 0 $desctop60px;
    cursor: pointer;
    width: fit-content;

    @media only screen and (max-width: 499px) {
        margin: $mobile10px 0 $mobile30px;
    }

    &:hover {
        opacity: 0.7;
    }

    p {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop10px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $redColor;
        margin-right: $desctop10px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            letter-spacing: $mobile1px;
            margin-right: $mobile10px;
        }
    }

    img {
        width: $desctop28px;
        height: $desctop24px;

        @media only screen and (max-width: 499px) {
            width: $mobile28px;
            height: $mobile24px;
        }
    }
}
