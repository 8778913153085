@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.firstScreen {
    background: $blueGradient;
    height: 100vh;
    min-height: 42vw;
    padding: $desctop40px $desctop135px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: auto;
        min-height: 50vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $desctop40px $mobile20px $mobile42px $mobile20px;
        height: auto;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop60px;
        line-height: $desctop60px;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile34px;
            line-height: $mobile44px;
        }

        span {
            color: $redColor;
        }
    }

    &__subTitle {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop32px;
        color: $whiteColor;
        opacity: 0.89;
        margin: $desctop40px 0;
        max-width: 28vw;
        margin-bottom: $desctop80px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile21px;
            max-width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__button {
        @include mainBtnStyle;
        @include filledBtn;
    }

    &__backgroundImg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10vw;
        height: 40vw;

        @media only screen and (max-width: 499px) {
            position: static;
            top: initial;
            transform: translate(-6vw, 5vw);
            height: auto;
            width: 100vw;
        }
    }
}

.socialIcons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-46%);

    @media only screen and (max-width: 499px) {
        top: 15%;
        transform: initial;
    }

    &__link {
        padding: $desctop36px;

        @media only screen and (max-width: 499px) {
            padding: $mobile15px $mobile30px;
        }

        &:hover {
            svg path {
                fill: $redColor;
            }
        }
    }

    svg {
        width: $desctop13px;
        height: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: $mobile13px;
            height: $mobile13px;
        }
    }
}

.container {
    background: $martiniqueapprox;
    border-top: $desctop2px solid $martiniqueapproxLight;
    border-bottom: $desctop2px solid $martiniqueapproxLight;
    padding: $desctop60px $desctop135px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 499px) {
        border-top: $mobile2px solid $martiniqueapproxLight;
        border-bottom: $mobile2px solid $martiniqueapproxLight;
        padding: $mobile42px $mobile20px;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: rgba(196, 196, 196, 0.1);
        padding: $desctop45px $desctop20px $desctop45px $desctop30px;
        width: 25vw;

        @media only screen and (max-width: 499px) {
            padding: $mobile30px $mobile20px;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: $mobile20px;
            }
        }

        &_img {
            height: $desctop75px;

            @media only screen and (max-width: 499px) {
                height: $mobile75px;
            }
        }

        &_title {
            font-family: $Montserrat900;
            font-size: $desctop25px;
            line-height: $desctop45px;
            color: $whiteColor;
            margin: $desctop27px 0 $desctop6px 0;

            @media only screen and (max-width: 499px) {
                font-size: $mobile20px;
                line-height: $mobile20px;
                margin: $mobile20px 0 $mobile10px 0;
            }
        }

        &_subTitile {
            font-family: $Montserrat400;
            font-size: $desctop16px;
            line-height: $desctop32px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile26px;
            }
        }

        &_button {
            @include mainBtnStyle;
            @include filledBtn;
            height: $desctop50px;
            min-width: 15vw;
            margin-top: $desctop40px;

            @media only screen and (max-width: 499px) {
                margin-top: $mobile30px;
            }
        }
    }
}
