@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.firstScreen {
    background: $martiniqueapprox;
    height: 100vh;
    min-height: 42vw;
    padding: $desctop40px $desctop135px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: auto;
        min-height: 50vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $desctop40px $mobile44px $desctop40px $mobile20px;
        height: auto;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop60px;
        line-height: $desctop60px;
        color: $whiteColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile34px;
            line-height: $mobile44px;
            margin-top: $mobile24px;
        }

        span {
            color: $redColor;
        }
    }

    &__subTitle {
        font-family: $Montserrat400;
        font-size: $desctop16px;
        line-height: $desctop32px;
        color: $whiteColor;
        opacity: 0.89;
        margin: $desctop45px 0 $desctop60px;
        max-width: 28vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            line-height: $mobile21px;
            max-width: 100%;
            margin-bottom: $mobile30px;
        }
    }

    &__button,
    &__buttonFilled {
        @include mainBtnStyle;
        margin-right: $desctop24px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            letter-spacing: $mobile1px;
            border-radius: $mobile70px;
            height: $mobile42px;
            width: 45%;
            margin: 0 0 $mobile20px 0;
        }
    }

    &__button {
        @include transparentBtn;
    }

    &__buttonFilled {
        @include filledBtn;
    }

    &__backgroundImg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10vw;
        width: 45.7vw;
        height: 31.8vw;

        @media only screen and (max-width: 499px) {
            position: static;
            top: initial;
            transform: translate(-5.5vw, 7vw);
            height: auto;
            width: 100vw;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $mobile20px;
        }
    }
}

.socialIcons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-46%);

    @media only screen and (max-width: 499px) {
        top: 15%;
        transform: initial;
    }

    &__link {
        padding: $desctop36px;

        @media only screen and (max-width: 499px) {
            padding: $mobile15px $mobile30px;
        }

        &:hover {
            svg path {
                fill: $redColor;
            }
        }
    }

    svg {
        width: $desctop13px;
        height: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: $mobile13px;
            height: $mobile13px;
        }
    }
}

.container {
    background: $martiniqueapprox;
    padding: $desctop60px $desctop135px 0;
    border-top: $desctop2px solid $martiniqueapproxLight;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px 0;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile30px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop60px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile60px;
        }

        &_text {
            line-height: $desctop32px;
            font-size: $desctop16px;
            font-family: $Montserrat400;
            color: $whiteColor;
            max-width: 85%;

            @media only screen and (max-width: 499px) {
                line-height: $mobile26px;
                font-size: $mobile14px;
                max-width: 100%;
            }

            b {
                font-family: $Montserrat900;
                margin-right: $desctop4px;
            }
        }
    }
}

.mainPic {
    text-align: center;
    display: flex;
    width: 100%;
    height: 55vw;

    @media only screen and (max-width: 499px) {
        justify-content: center;
        height: 110vh;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__imgMobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;
        display: none;

        @media only screen and (max-width: 499px) {
            display: block;
        }
    }
}
