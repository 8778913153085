@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $desctop80px;
    padding: 0 $desctop30px 0 $desctop135px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: $blueGradient;

    @media only screen and (max-width: 499px) {
        height: $mobile70px;
        padding: 0 $mobile20px 0 $mobile20px;
    }

    &__leftSide,
    &__rightSide {
        display: flex;
        align-items: center;
        z-index: 1000;
    }

    &__rightSide {
        @media only screen and (max-width: 499px) {
            display: none;
        }

        &_margin {
            margin: 0 $desctop10px;
        }

        &_name {
            max-width: 20vw;
            width: initial;
            margin-right: 1.35vw;
        }
    }

    &__logo {
        margin-right: $desctop35px;
        z-index: 1;

        &_img {
            width: $desctop70px;
            height: $desctop27px;

            @media only screen and (max-width: 499px) {
                width: $mobile70px;
                height: $mobile28px;
            }
        }
    }

    &__links {
        display: flex;
        align-items: center;

        &_item {
            display: flex;
            align-items: center;

            position: relative;
            z-index: 101;

            &>div {
                cursor: default;
            }

            &>div,
            &>a {
                padding: 0.9vw $desctop25px 0.9vw $desctop25px;
            }

            &:hover {
                .header__subLinks {
                    opacity: 1;
                    user-select: auto;
                    pointer-events: auto;
                }

                &>div,
                &>a {
                    color: $redColor;
                }
            }

            svg {
                display: none;

                @media only screen and (max-width: 499px) {
                    display: flex;
                }
            }
        }

        &_item>* {
            font-family: $Montserrat500;
            color: $whiteColor;
            font-size: $desctop10px;
            letter-spacing: $desctop1px;
            text-transform: uppercase;

            &:hover {
                color: $redColor;
            }
        }
    }

    &__subLinks {
        @include sublink;
    }

    &__linksWrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }
}

.headerNarrow {
    padding: 0 $desctop30px;
}
