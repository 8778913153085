@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.media {
    background: $whiteColor;
    padding: $desctop60px $desctop135px $desctop60px;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: $Montserrat900;
        color: $tolopeaColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop30px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: $desctop30px;

        &_link {
            width: 21%;
            transition: all 0.25s;

            @media only screen and (max-width: 499px) {
                width: 48%;
            }

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 $desctop5px $desctop10px rgba(0, 0, 0, 0.1);
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
