@import '../assets/styles/variables';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;

    @media only screen and (max-width: 499px) {
        position: absolute;
        left: $mobile20px;
        bottom: $mobile42px;
        flex-direction: row;
    }

    &__hideOnDesctop {
        display: none;

        @media only screen and (max-width: 499px) {
            display: flex;
        }
    }

    &:hover {
        .container__lang {
            color: $redColor;
        }
    }

    &__flagIcon {
        width: $desctop22px;
        height: $desctop22px;
        border: $desctop1px solid rgba(241, 241, 241, 0.4);
        border-radius: 50%;
        box-sizing: content-box;

        @media only screen and (max-width: 499px) {
            width: $mobile21px;
            height: $mobile21px;
        }
    }

    &__lang {
        font-family: $Montserrat900;
        font-size: $desctop10px;
        letter-spacing: $desctop1px;
        color: $whiteColor;
        text-transform: uppercase;
        position: absolute;
        transform: rotate(-90deg);
        bottom: -1.5vw;
        margin-left: $desctop1px;
        user-select: none;
        transition: all 0.2s;

        @media only screen and (max-width: 499px) {
            position: static;
            transform: initial;
            font-size: $mobile10px;
            letter-spacing: $mobile1px;
            margin-left: $mobile1px;
            color: $tolopeaColor;
            padding-left: $mobile20px;
        }
    }
}
