@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.subscribe {
    background: linear-gradient(128.21deg,#313162 -12.97%,#19192d 102.51%);
    padding: $desctop60px $desctop135px $desctop60px;
    display: flex;

    @media only screen and (max-width: 499px) {
        padding: $mobile42px $mobile20px;
        flex-direction: column;
    }

    &__title {
        font-family: $Montserrat900;
        color: $whiteColor;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        font-size: $desctop12px;
        white-space: nowrap;
        min-width: 7vw;

        @media only screen and (max-width: 499px) {
            letter-spacing: $mobile1px;
            font-size: $mobile12px;
            min-width: 100%;
            margin-bottom: $mobile10px;
        }
    }

    &__rightSide {
        margin: $desctop50px 0 0 $desctop5px;

        @media only screen and (max-width: 499px) {
            margin: $desctop30px 0 0 $desctop5px;
        }

        &_title {
            margin-bottom: $desctop20px;
            font-family: $Montserrat900;
            line-height: $desctop50px;
            font-size: $desctop40px;
            color: $whiteColor;
            max-width: 50vw;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile10px;
                line-height: $mobile42px;
                font-size: $mobile25px;
                max-width: 100%;
            }

            span {
                color: $redColor;
            }
        }
    }

    &__bottom {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        &_text {
            font-family: $Montserrat400;
            line-height: $desctop34px;
            font-size: $desctop14px;
            color: $whiteColor;
            width: 20vw;
            margin-right: $desctop70px;

            @media only screen and (max-width: 499px) {
                min-width: 100%;
                width: 100%;
                font-size: $mobile12px;
                line-height: $mobile24px;
                padding-right: 0;
                margin-right: 0;
                margin-bottom: $mobile20px;
            }
        }

        &_input {
            outline: none;
            border: none;
            background: transparent;
            height: $desctop63px;
            min-width: 22vw;
            border: 0;
            border-bottom: $desctop1px solid $whiteColor;
            font-size: $desctop24px;
            color: $whiteColor;
            font-family: $Montserrat400;
            margin-right: $desctop30px;

            @media only screen and (max-width: 499px) {
                height: $mobile42px;
                min-width: 100%;
                border-bottom: $mobile1px solid $whiteColor;
                font-size: $mobile14px;
                margin: 0 0 $mobile30px 0;
                border-radius: initial;
            }

            &::placeholder {
                color: $whiteColor;
            }
        }

        &_inputError {
            color: $redColor;
            border-bottom: $desctop1px solid $redColor;

            @media only screen and (max-width: 499px) {
                border-bottom: $mobile1px solid $redColor;
            }

            &::placeholder {
                color: $redColor;
            }
        }

        &_button {
            @include mainBtnStyle;
            @include filledBtn;
        }
    }
}
