@import '../../../assets/styles/variables';

.aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(103.05deg, #313162 -12.97%, #19192D 220%);
    border-right: $desctop2px solid $martiniqueapproxLight;
    border-top: $desctop2px solid $martiniqueapproxLight;

    svg {
        width: $desctop16px;
        height: $desctop16px;
    }
}
